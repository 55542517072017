import axios from 'axios';

import thoth from '../api/thoth';
import { ramses } from '../api';

const BASE_URL = process.env.VUE_APP_BASE_URL;

const token = sessionStorage.getItem('token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export async function uploadLiquidation(file) {
    try {
        const formData = new FormData();
        formData.append('liquidation', file);
        const { status } = await thoth.post('/upload/liquidation', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function uploadTerminal(file) {
    try {
        const formData = new FormData();
        formData.append('terminal', file);
        const { status } = await axios.post(`${BASE_URL}/upload/terminal`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function uploadExtract(file) {
    try {
        const formData = new FormData();
        formData.append('extract', file);
        const { status } = await thoth.post('/upload/updateExtract', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function uploadChips(file) {
    try {
        const formData = new FormData();
        formData.append('chips', file);
        const { status } = await axios.post(`${BASE_URL}/upload/chips`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function uploadInstallments(file) {
    try {
        const formData = new FormData();
        formData.append('installments', file);
        const { status } = await axios.post(`${BASE_URL}/upload/installments`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function extractAlexandria(file) {
    try {
        const formData = new FormData();
        formData.append('extract', file);
        const { status } = await axios.post(`${BASE_URL}/upload/extract`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return status;
    } catch ({ response }) {
        return response.status;
    }
}

async function importBankSlip(file) {
    try {
        const FormData = require('form-data');

        const data = new FormData();
        data.append('file', file);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'saveFileBankSlip',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        };

        const response = await thoth.request(config);
        return response.status;
    } catch ({ response }) {
        return response.status;
    }
}

async function importTerminalsBackoffice(file) {
    try {
        const FormData = require('form-data');

        const data = new FormData();
        data.append('terminal', file);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'upload/terminal',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        };

        const response = await thoth.request(config);
        return response.status;
    } catch ({ response }) {
        return response.status;
    }
}

async function importRedeDisqualification(file) {
    try {
        const FormData = require('form-data');

        const data = new FormData();
        data.append('file', file);

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: '/redeDisqualification',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        };
console.log(config);
        const response = await ramses.request(config);
        console.log(response)
        return response.status;
    } catch ({ response }) {
        return response.status;
    }
}

export {
    uploadTerminal,
    uploadExtract,
    uploadChips,
    uploadInstallments,
    extractAlexandria,
    importBankSlip,
    importTerminalsBackoffice,
    importRedeDisqualification
};
