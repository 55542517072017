<template>
  <b-row v-if="renderPage">
    <b-container>
      <b-overlay
        :show="showLoading"
        :opacity="1"
        variant="light"
        rounded="sm"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <strong>Enviando Arquivo, Aguarde...</strong>
            <b-icon
              icon="stopwatch"
              font-scale="3"
              animation="cylon"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
            <b-spinner
              type="grow"
              variant="primary"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
          </div>
        </template>
        <b-card title="Importação  de Arquivos">
          <validation-observer>

            <b-form>
              <b-form-group
                label="Tipo do Arquivo"
                label-for="v-file"
              >
                <v-select
                  v-model="selectedFileOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="fileOptions"
                  placeholder="Selecione o Tipo do Arquivo"
                />
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Upload"
                  :rules="`required|${
                    selectedFileOption.title === 'Liquidação' ? 'veripag-liquidation' : ''
                  }`"
                >
                  <b-form-file
                    v-model="file"
                    class="mb-2"
                    placeholder="Arraste ou selecione um arquivo"
                    drop-placeholder="Arraste o arquivo aqui."
                    :disabled="isDisabledFile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
              <b-button
                variant="primary"
                :disabled="!file"
                class="btn-upload"
                @click="upload"
              >Enviar Arquivo</b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </b-row>
</template>
<script>
import {
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCard,
    BContainer,
    BForm,
    BSpinner,
    BOverlay,
    BIcon,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
    extractAlexandria, uploadTerminal, uploadExtract, uploadChips, uploadInstallments, uploadLiquidation, importBankSlip, importTerminalsBackoffice,importRedeDisqualification
} from '@/service/file/archive';
import '@validations';

export default {
    components: {
        BFormFile,
        BFormGroup,
        BButton,
        BRow,
        BCard,
        BContainer,
        BForm,
        vSelect,
        BSpinner,
        BOverlay,
        BIcon,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            file: null,
            showLoading: false,
            selectedFileOption: {
                title: 'Selecione o Tipo de Arquivo',
            },
            fileOptions: [
                { title: 'Liquidação' },
                { title: 'Estoque de chips' },
                { title: 'Atualizar Installments' },
                { title: 'Extrato Alexandria' },
                { title: 'Boletos' },
                { title: 'Terminal Backoffice' },
                { title: 'Descredenciamento Rede' },
            ],
            renderPage: true,
        };
    },
    computed: {
        isDisabledFile() {
            return this.selectedFileOption.title === 'Selecione o Tipo de Arquivo';
        },
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    methods: {
        async upload() {
            this.showLoading = true;

            const { file } = this;
            const { title } = this.selectedFileOption;

            switch (title) {
            case 'Terminal': {
                const terminalResponse = await uploadTerminal(file);
                if (terminalResponse === 200) { this.success(); } else { this.error(); }
                break;
            }

            case 'Boletos': {
                const boletoResponse = await importBankSlip(file);

                if (boletoResponse === 200) { this.success(); } else { this.error(); }

                break;
            }

            case 'Liquidação': {
                const liquidationResponse = await uploadLiquidation(file);
                if (liquidationResponse === 200) { this.success(); } else { this.error(); }
                break;
            }

            case 'Estoque de chips': {
                const chipsResponse = await uploadChips(file);
                if (chipsResponse === 200) { this.success(); } else { this.error(); }
                break;
            }

            case 'Atualizar Installments': {
                const installmentsResponse = await uploadInstallments(file);
                if (installmentsResponse === 200) { this.success(); } else { this.error(); }
                break;
            }

            case 'Extrato Alexandria': {
                const installmentsResponse = await extractAlexandria(file);
                if (installmentsResponse === 200) { this.success(); } else { this.error(); }
                break;
            }

            case 'Terminal Backoffice': {
                const installmentsResponse = await importTerminalsBackoffice(file);
                if (installmentsResponse === 200) { this.success(); } else { this.error(); }
                break;
            }
            case 'Descredenciamento Rede': {
                const installmentsResponse = await importRedeDisqualification(file);
                if (installmentsResponse === 200) { this.success(); } else { this.error(); }
                break;
            }
            default: {
                this.error();
                break;
            }
            }
            this.cleanInputs();
            this.showLoading = false;
            //
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>O Arquivo foi Enviado com Sucesso </span> ',
            });
        },
        error() {
            this.$swal({
                icon: 'error',
                html: '<span>Falha no envio de Arquivo </span> ',
            });
        },
        cleanInputs() {
            this.file = null;
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/upload-style.scss";

.custom-file, .custom-file-input {
  background-color: red !important;
}
</style>
